<template>
  <div class="content">
      <div class="content-item">
        <!-- <van-nav-bar title="个人信息修改" :border='false' @click-left="onClickLeft" left-arrow  class="content-title"/> -->
        <van-image round width="90px" height="90px" :src="img" />
        <van-uploader :after-read="afterRead" class="upload-title">点击更换头像</van-uploader>
      </div>
      <van-field v-model="text" label="昵称" :colon='true' />
      <van-field v-model="tel" type="tel" label="手机号" :colon='true' :is-link='true' readonly @click="changeTel" >
          <!-- <template #input >
            <span class="left-text">已绑定</span>
        </template> -->
          <template #extra >
            <span class="right-text">重新绑定</span>
        </template>
      </van-field>
      <van-field v-model="number" type="number" label="微信号" :colon='true' :is-link='true' readonly   @click="changeWeChat" >
          <template #extra >
            <span class="right-text">重新绑定</span>
        </template>
      </van-field>
      <van-field v-model="password" type="password" label="密码更改" :is-link='true'  readonly  @click="changePwd">
          <template #extra >
            <span class="right-text">点击修改</span>
        </template>
      </van-field>
      <div class="upload-botm">
          <van-button round block color="#23B8FF" class="botm-btn">退出登录</van-button>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            img: require('@/assets/image/header.png'),
            text: '我爱吃水果',
            tel: '13219322242',
            number: '13219322242',
            password:''
        }
    },
    methods: {
        onClickLeft() {
            window.history.go(-1);
        },
        afterRead(file) {
            this.img = file.content
        },
        changeTel() {
            this.$router.push({name:'changetel',query:{id:this.id}})
        },
        changeWeChat() {
            this.$router.push({name:'changewechat',query:{id:this.id}})
        },
        changePwd() {
            this.$router.push({name:'changepassword',query:{id:this.id}})
        }
    }
}
</script>

<style lang="less" scoped>
.content-item {
    width: 100%;
    height: 220px;
    background: url('../assets/image/inf.png');
}
/deep/ .van-nav-bar__title {
    font-size: 16px;
    font-weight: 700;
    color: #fff ;
}
.van-nav-bar {
    background: url('../assets/image/inf.png');
}
 /deep/ .van-icon-arrow-left:before {
    color: #fff;
}
.content-title {
    padding-top: 30px;
}
.upload-title {
    font-size: 12px;
    color: #fff;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
 /deep/ .van-field__label {
    color: #333333;
    margin-right: 0;
    font-size: 12px;
}
 /deep/ .van-field__control {
     color: #666666;
     font-size: 12px;
}
.upload-botm {
    padding: 20px;
    margin-top: 180px;
    .van-button--round {
    border-radius: 10px;
  }
}
.left-text {
    position: absolute;
    left: 45%;
    color: #23B8FF;
}
.right-text {
    position: absolute;
    right: 35px;
    color: #666666;
    font-size: 10px;
}
</style>